import axios from "axios";
import services from "./Common";

const authAxios = async (a_params) => {
  try {
    const token = await services.getToken();

    const local = "http://localhost:3000";
    const live = "http://51.20.115.46/api";

    const axiosParams = {
      ...a_params,
      url: live + a_params.url,
      headers : {
        Authorization: token,
      },
    };
    const result = await axios(axiosParams);
    return result;
  } catch (error) {
    console.log(error , "axios error")
    throw error;
  }
};

export default authAxios;

