


import React, { useState } from 'react';
import { Button, Text } from '../../components/elements';
import { LabelField } from '../../components/fields';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Logo } from "../../components";
import data from "../../data/master/header.json";
import PageLayout from '../../layouts/PageLayout';
import { addContactForm } from '../../slices/countryManagementSlice';
import { useDispatch } from 'react-redux';
const ContactUs = () => {
    const email_pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name : "",
        phone: "",
        email: "",
        countryCode: '+1',
        state :"",
        city : ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        
        const fullPhoneNumber = `${formData.countryCode}${formData.phone}`;
    
        const payload = {
            name : formData.name,
            email: formData.email,
            phone: fullPhoneNumber,
            state : formData.state,
            city : formData.city
        };
    if (!formData.name) {
        toast.warn("Name is required!");
      } else if (!formData.email) {
        toast.warn("Email is required!");
      }
      else if (!email_pattern.test(formData.email)) {
        toast.warn("Invalid email");
      }else if(!formData.phone){
         toast.warn("phone is required")
      } else if (!formData.state) {
        toast.warn("State is required!");
      } else if(!formData.city) {
        toast.warn("City is required")
      }else{
        dispatch(addContactForm(payload))
          .unwrap()
          .then(() => {
            toast.success("Data added successfully");
            // setTimeout(() => {
            //   navigate("/team-list");
            // }, 1000);
          })
          .catch((error) => toast.error("Something went wrong"));
      }

    
    };

    return (
        <div style={{ backgroundImage: 'url(/images/contactUsImage.png)'  , 
            backgroundSize: 'cover',         // Makes the image cover the entire div
            backgroundPosition: 'center',  // Centers the image within the div
            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
            width: '100%',                 // Adjusts width as needed
            }}>
      <div className = "custom-margin px-5 " >
        <div className="mx-5 ">
        <Logo
        src={data?.logo.src}
        alt={data?.logo.alt}
        name={data?.logo.name}
        href={data?.logo.path}
      />
            

      <div className = " mx-md-n5 contact-form-container ">
      <div className = " ">
     
            <h1 className = "custom-heading mb-3">Coming Soon</h1>
            <Text className = "custom subheading mb-5">Get Notified When We Launch</Text>
            <form onSubmit={onSubmit}>
                <div className="form-group align-items-center ">
                <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        className='custom-input'
                        
                    />
                   
                       
                    
                    <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        className='custom-input'
                    />

                     <div className="d-flex align-items-center position-relative">
                                    <input
                                        type="text"
                                        name="phone"
                                        maxLength="10"
                                        pattern="[0-9]*"
                                        placeholder="Mobile number"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        onBlur={(e) => {
                                            if (e.target.validationMessage)
                                                toast.warn("Please enter a valid number!");
                                        }}
                                        className='custom-input'
                                        style={{ paddingLeft: '35px' }} // Add space for the country code
                                    />
                                    <span className="country-code" style={{
                                        position: 'absolute',
                                        left: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        pointerEvents: 'none', // Prevent clicks on the span
                                        color: '#999' // Style as needed
                                    }}>
                                        {formData.countryCode}
                                    </span>
                                </div>
                                
                       {/* <input
                            type="tel"
                            name="phone"
                            maxLength="13"
                            pattern="[1-9]{1}[0-9]{9}"
                            // className="phoneInput"
                            placeholder="Mobile number"
                            value={formData.phone}
                            onChange={handleChange}
                            onBlur={(e) => {
                                if (e.target.validationMessage)
                                    toast.warn("Please enter a valid number!");
                            }}
                            className='custom-input'
                        /> */}

                        
                       <div className = "d-flex gap-3">
                        <input
                        type="text"
                        name="state"
                        placeholder="State"
                        value={formData.state}
                        onChange={handleChange}
                        className = "custom-shortinput"
                       />
                       <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    className = "custom-shortinput"
                   
                />
                </div> 




                </div>

                <div>
                    <Button type="submit" className="custom-button">
                        Submit
                    </Button>
                </div>
            </form>
            <ToastContainer />
        </div>
        <div>
            <img src="/images/contact.png" />
        </div>
        </div>
        </div>
        </div>
        </div>
    );
};

export default ContactUs;

