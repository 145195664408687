import React from "react";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { adminLogin } from "../../slices/authSlice";
import { useHistory } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();

  const [auth, setAuth] = React.useState({});
  const email_pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const notify = () => toast.error("Enter valid credential");

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setAuth((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSignIn = () => {
    if (email_pattern.test(auth.email)) {
      dispatch(adminLogin(auth))
        .unwrap()
        .then((res) => {
          toast.success("Login Successful!");
          setTimeout(() => {
            localStorage.setItem("token", res.jwtToken);
            localStorage.setItem("role", res.role);

            if (res.role === "admin") {
              const allowedPermission = res.permissions
                ?.filter((permission) => permission.isActive)
                .map((item) => item.checkLabel);
              localStorage.setItem(
                "permissions",
                JSON.stringify(allowedPermission)
              );
            } else {
              const permission = ["super_admin"];
              localStorage.setItem("permissions", JSON.stringify(permission));
            }
            navigate("/");
          }, 1000);
        })
        .catch((err) => {
          notify();
          console.log(err, "Error");
        });
    } else {
      notify();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSignIn();
    }
  };

  return (
    <Box className="mc-auth">
      <Image
        src={data?.pattern.src}
        alt={data?.pattern.alt}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo ml-16"
        />
        {/* <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading> */}
        <Form className="mc-auth-form" >
          <Heading as="h4" className="mc-auth-title font-semibold">
            {data?.title}
          </Heading>
          {data?.input.map((item, index) => (
            <IconField
              key={index}
              icon={item.icon}
              type={item.type}
              option={item.option}
              classes={item.fieldSize}
              placeholder={item.placeholder}
              passwordVisible={item.passwordVisible}
    
              name={item.name}
              onChange={handleChange}
              onKeyDown = {handleKeyDown}
            />
          ))}
          <Button
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
            onClick={handleSignIn}
          >
            {data?.button.text}
          </Button>
          <Anchor className="mc-auth-forgot" href={data?.forgot.path}>
            {data?.forgot.text}
          </Anchor>
        </Form>
      </Box>
      <ToastContainer />
    </Box>
  );
}



export const removeAuthToken = () => {
  // const navigate = useNavigate();
  // Remove the token from localStorage
  localStorage.removeItem("token");

  // If using sessionStorage instead of localStorage
  sessionStorage.removeItem("token");

  // Clear all localStorage data (optional)
  localStorage.clear();

  // navigate("/login")

  // Clear all sessionStorage data (optional)
  // sessionStorage.clear();
};




export const logout = () => {
  // Clear any stored session data, such as tokens
  removeAuthToken();
 
};


